/* eslint-disable @typescript-eslint/no-unused-vars,jsx-a11y/tabindex-no-positive */
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'

import { SplunkLoginComponent } from '@splunk-iteng/splunk-login-react-component'

import {
  AUTHORIZE_PARAMS,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  MARKETING_CONFIG,
  URLS
} from 'src/Constants/Constants'
import { LOCALES } from 'src/Constants/Locales'
import { ReactComponent as Warning } from '../../Images/warning.svg'

import { getMarketingContent } from '../../Utils/Marketing'
import { cleanCookie, getLocale, submitWithCaptcha } from '../../Utils/Utils'
import { LoginCallbackResponseType } from '../../Types/LoginCallbackResponseType'
import logo from '../../Images/login-logo-2.svg'
import { useAppSelector } from '../../Redux/Store'
import { validateRedirectUrl } from '../../Utils/Router'
import { useIsUserLoggedInQuery } from '../../Redux/Apis/LoginApi'
import { FeedbackPopup } from '../FeedbackPopup/FeedbackPopup'
import { Captcha } from '../Captcha/Captcha'

export function Login() {
  useIsUserLoggedInQuery(false)
  const { allowedDomains, isLoggedIn } = useAppSelector((state) => state.login)
  const params = AUTHORIZE_PARAMS
  const { pathname } = useLocation()
  if (pathname !== '/') {
    const urlLocale = pathname.replace('/', '')
    if (!LOCALES.supportedLocale.includes(urlLocale)) {
      window.location.href = '/not_found'
    }
  }

  const [, setFromURI] = useState<string | null | undefined>(null)
  const [error, setErrorMessage] = useState<string | null | undefined>(null)
  const [redirectUrl, setRedirectUrl] = useState<string | null | undefined>(
    null
  )
  const { localeData, receivedLocale, receivedForwardLocale } = getLocale()

  useEffect(() => {
    document.title = 'Log Into Your Splunk Account | Splunk'
    cleanCookie('pd_b_visited')
    getMarketingContent()
  }, [localeData, receivedLocale])

  useEffect(() => {
    const { redirectUrl: r, fromURI: f } = validateRedirectUrl(
      allowedDomains,
      receivedLocale,
      true
    )
    if (isLoggedIn) {
      window.location.href = r
    }

    setRedirectUrl(r)
    setFromURI(f)
  }, [allowedDomains, receivedLocale, isLoggedIn])

  const loginCallback = (event: LoginCallbackResponseType) => {
    const { data, type } = event

    if (type === LOGIN_SUCCESS) {
      const { data: d } = data
      const { redirect_url: redirect, user_idp_id: userIdpId } = d
      window.dataLayer.push({
        event: 'login',
        status: 'success'
      })
      window.dataLayer.push({
        user_id: userIdpId
      })
      if (data.data.action === 'redirect') {
        window.location.href =
          redirectUrl || redirect || URLS.DEFAULT_REDIRECT_URL
      }
    }
    if (type === LOGIN_FAILURE) {
      window.dataLayer.push({
        event: 'login',
        status: 'failure'
      })
    }
    if (type === undefined) {
      window.dataLayer.push({
        event: 'login',
        status: 'failure'
      })
    }
  }

  if (allowedDomains) {
    params.redirect_uri = redirectUrl || URLS.DEFAULT_REDIRECT_URL
  }
  const { baseUrl } = MARKETING_CONFIG
  return (
    <div id="content">
      <section className="splunk-login">
        <div className="logo-container">
          <a tabIndex={19} href={baseUrl}>
            <img src={logo} alt="Splunk home" width={122} />
          </a>
        </div>
        <div className="main-login">
          {typeof error === 'string' && error.length ? (
            <div className="login-error">
              <Warning />
              &nbsp; {error}
              <button type="button" onClick={() => setErrorMessage('')}>
                x
              </button>
            </div>
          ) : null}
          <div
            id="themeSelect"
            className="black-theme-form-container login-box-container"
          >
            <SplunkLoginComponent
              notification={loginCallback}
              setErrorMessage={setErrorMessage}
              preSubmit={submitWithCaptcha}
              Captcha={Captcha}
              authorizeEndpoint={URLS.DEFAULT_REDIRECT_URL}
              authorizeParams={params}
              locale={receivedLocale}
              forwardlocale={receivedForwardLocale}
              signupUrl={URLS.OKTA_HOST}
              serverUrl={URLS.LOGIN_HOST}
              createAccountLink={redirectUrl || ''}
              full-login
            />
          </div>
          <div
            data-at-location="Login-Promotion"
            className="mkt-banner-container"
          />
        </div>
        <div id="login-links" className="login-links" />
        <FeedbackPopup />
      </section>
    </div>
  )
}
